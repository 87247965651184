<!-- eslint-disable vue/no-unused-components -->
<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!-- Profile cover -->
            <div class="profile user-profile">

                <div class="profiles_banner">
                    <template>
                        <a v-if="currentUser['banner_photo']" target="_blank" :href="currentUser['banner_photo']">
                            <img :src="currentUser['banner_photo']" alt="">
                        </a>
                        <img v-else src="/assets/images/avatars/profile-cover.jpg" alt="">
                    </template>
                </div>
                <div class="profiles_content">

                    <div class="profile_avatar">
                        <div class="profile_avatar_holder">
                            <template>
                                <a v-if="currentUser['profile_photo']" target="_blank" :href="currentUser['profile_photo']">
                                    <img :src="currentUser['profile_photo']" alt="">
                                </a>
                                <img v-else :src="defaultImageUrl()" alt="">
                            </template>
                        </div>
                    </div>

                    <div class="profile_info col-sm-12 col-xs-12">
                        <h5 class="text-center">
                            Balance: <span class="text-uppercase">{{ currencySymbol(currency) }}</span>{{ wallet || '0.0' }}
                        </h5>
                        <h1 class="text-center">
                            {{ currentUser['name'] }}
                            <verified-badge v-if="currentUser['verified'] == 'true'" mt="one"></verified-badge>
                        </h1>
                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <h4 class="my-3 font-bold">Referral Commisions</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th>Name</th>
                                                <th>Received</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="referral_commision in referral_commisions" v-bind:key="referral_commision['id']">
                                                <td>
                                                    <span v-if="referral_commision && referral_commision['referred_user']" class="text-capitalize">{{ referral_commision['referred_user']['name'] }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-capitalize">{{ referral_commision['currency'] }} {{ parseFloat(referral_commision['amount']).toFixed(2) }}</span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-success">paid</span>
                                                </td>
                                                <td>{{ formatDateTimeNice(referral_commision['created_at']) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="showWithdraw == true" class="text-center my-3">
                                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                                </div>
                                <div v-if="showWithdraw == false && referral_commisions.length == 0" class="text-center my-3">
                                    <h4>No referral_commisions yet</h4>
                                </div>
                                <div v-if="pagination" class="text-center my-3">
                                    <button v-if="pagination['prev_page'] == pagination['current_page']" class="btn btn-secondary">Previous</button>
                                    <button v-else class="btn btn-primary" @click="getReferralCommisions(pagination['prev_page'], per_page)">Previous</button>
                                    <button class="btn btn-secondary mx-3">{{ pagination['current_page'] }}</button>
                                    <button v-if="pagination['next_page'] == pagination['current_page']" class="btn btn-secondary">Next</button>
                                    <button v-else class="btn btn-primary" @click="getReferralCommisions(pagination['next_page'], per_page)">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

</div>
</template>
<script>
import {
  ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import ProgressLoader from '@/components/ProgressLoader'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
  name: 'ReferralsScreen',
  components: {
    Header,
    Sidebar,
    ProgressLoader,
    VerifiedBadge
  },
  data () {
    return {
      user: '',
      currentUser: {},
      thisUser: {},
      name: '',
      account_no: '',
      account_type: '',
      bank: '',
      bank_account: '',
      wallet: '',
      currency: '',
      referral_commisions: '',
      pagination: '',
      full_name: '',
      description: '',
      loading: false,
      showWithdraw: false,
      loadingWithdraw: false,
      toReceive: 0,
      page: 1,
      per_page: 100
    }
  },
  beforeMount () {},
  mounted () {
    this.thisUser = this.$store.getters['user/currentUser']
    this.currentUser = this.thisUser
    this.id = this.thisUser.id

    this.email = this.currentUser.email
    this.phone = this.currentUser.phone
    this.full_name = this.currentUser.name

    this.getWallet()
    this.getReferralCommisions(this.page, this.per_page)
  },
  methods: {
    async getWallet () {
      this.toggleProgress('show')
      const apiResponse = await ApiService.GetWallet()
      // console.log('GetMe',apiResponse)
      if (apiResponse.status === 'success') {
        this.wallet = parseFloat(apiResponse.data).toFixed(2)
        this.currency = apiResponse.currency
        this.currentUser = apiResponse.user
      }
      this.toggleProgress('hide')
    },
    async getReferralCommisions (page, per_page) {
      this.toggleProgress('show')
      const apiResponse = await ApiService.GetReferralAdvertCommisions(page, per_page)
      // console.log('UserFollowership',apiResponse)
      if (apiResponse.status === 'success') {
        this.referral_commisions = apiResponse.data
        this.pagination = apiResponse.pagination
      }
      this.toggleProgress('hide')
    }
  }
}
</script>
    
<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

.bannerCamera {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    border: 2px solid #FFF;
    font-size: 16px;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #c5ccd4;
    z-index: 10;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }

    .flex-xs>div {
        display: flex;
    }
}
</style>
